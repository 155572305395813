import React from "react";
import PageWrapper from "../components/page-wrapper";

function Projects() {
  return (
    <PageWrapper>
      <main>
        <h1>About</h1>
      </main>
    </PageWrapper>
  );
}

export default Projects;
